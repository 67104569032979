@import 'variables.scss';
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css');

/* BlogPage.css */
.white-heading {
    color: white;
}

.blog-container {
    padding: 50px 0;
    background-color: #023566;
    color: var(--text-color);
    position: relative; /* Ensure relative positioning for overlay */
}

.blog-card {
    background-color: #fff; /* White background for blog cards */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth effect */
    cursor: pointer; /* Change cursor to pointer on hover */
    align-content: center;
}
.blog-card:hover {
    transform: scale(1.05); /* Scale up the card on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase box shadow for pop-up effect */
}

.overlay {
    position: fixed;

    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: -1; /* Behind other content by default */
    backdrop-filter: blur(5px); /* Apply blur effect to background */
    transition: z-index 0.3s ease; /* Transition z-index for smooth effect */
}

.card-active {
    z-index: 5; /* Bring active card to the front */
}

.blog-card .card-title {
    color: #d50a0a; /* Use primary color for card titles */
    font-size: 18px;
}

.blog-card .card-text {
    color: #d50a0a; /* Use text color from color palette */
}


/* CSS for slideshow */
.slideshow {
    width: 100%; /* Adjust width as needed */
}

.home {
    padding: 100px 0;
    background-size: cover;
    background-position: center;
    background-color: var(--background-color);
    text-align: center;
    height: 100vh;

}

/* Navbar Styles */
.navbar {
    background-color: #023566FF;
    position: relative;
    text-wrap: auto;
}

.navbar-brand img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px; /* Adjust the left position as needed */
    max-width: 80px; /* Adjust the max-width of the logo */
    height: auto;
    text-wrap: auto;
}

.navbar-transparent {
    background-color: rgba(2, 53, 102, 0.8) !important;
}

/* Override default Navbar link styles */
.navbar-dark .navbar-nav .nav-link {
    color: #fff; /* Set text color for Navbar links */
    margin-right: 15px; /* Adjust spacing between Navbar links */
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: #fff; /* Set text color for Navbar links on hover */
}

.logo-container img {
    display: block;
    max-width: 100%;
    border-radius: 10vh;
    box-shadow: 0 2px 4px rgba(30%, 50%, 20%, 0.1); /* Adjust shadow color and intensity */
    text-wrap: auto;
}

.navbar-brand-content p {
    text-align: left; /* Align text content to the left */
    margin-right: 100px;
    text-wrap: auto;
}

.home h1 {
    font-size: 3rem;
    color: red;
    margin-top: -70px;
}

.home p {
    font-size: 1.5rem;
    color: red;
}

.home button {
    margin-top: 20px;
    background-color: var(--accent-color); /* Use accent color for button background */
    border-color: var(--accent-color);
}

.home button:hover {
    background-color: var(--secondary-color); /* Use secondary color on button hover */
}

.about {
    padding: 50px 0;
    background-color: var(--background-color);
    color: var(--text-color);
}

.about h1 {
    color: var(--primary-color);
    text-align: center;
}

.about p {
    margin-bottom: 20px;
}

/* Style for the container of the image and director text */
.image-container {
    text-align: center; /* Center align the content */
    margin-bottom: 20px; /* Add space below the container */
}

/* Style for the image inside the container */
.image-container img {
    max-width: 100%; /* Make sure the image does not exceed its container width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10vh; /* Apply border radius for rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
    margin-top: 50px;
}

/* Style for the director text */
.director-text {
    font-size: 16px; /* Set the font size */
    font-weight: bold; /* Make the text bold */
    margin-top: 10px; /* Add space above the text */
}

.services {
    padding: 50px 0;
    background-color: #023566;
    color: var(--text-color);
}

.services h1 {
    color: whitesmoke;
    text-align: center;
}

.services p {
    margin-bottom: 20px;
    color: #ffffff;
    text-align: center;
    font-weight: bolder;
}

.services ul {
    list-style-type: none;
    padding-left: 0;
    color: whitesmoke;
    text-align: center;
}

.services li {
    margin-bottom: 10px;
}

.image-container1 {

    height: auto;
    text-align: center; /* Center align the content */
    margin-bottom: 20px; /* Add space below the container */
}

/* Style for the image inside the container */
.image-container1 img {
   /* Make sure the image does not exceed its container width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10vh; /* Apply border radius for rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
    margin-top: 50px;
    margin-right: 70px;
}

/* Additional styles for links */
.services li a {
    color: whitesmoke; /* Set link color */
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;
}

.services li a:hover {
    color: #ffc107; /* Change hover color */
}

.contact-container {
    background-color: var(--color-primary); /* Use primary color for container background */
    padding: 40px;
    border-radius: 8px;
}

.logo-container {
    text-align: center;
    margin-bottom: 20px;
}

.contact-info {
    color: var(--color-text); /* Use text color from color palette */
    font-size: 16px;
    margin-bottom: 10px;
}

.contact-page {
    background-color: #fff; /* White background for form */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
    color: whitesmoke; /* Use primary color for form headings */
    margin-bottom: 20px;
}

.contact-form .form-control {
    border-radius: 4px;
}

.contact-form textarea {
    resize: vertical; /* Allow vertical resizing of textarea */
}

.contact-form button[type="submit"] {
    background-color: #03376c; /* Use primary color for submit button background */
    /*border-color: var(--color-primary); !* Use primary color for submit button border *!*/
}

.contact-form button[type="submit"]:hover {
    background-color: #173538; /* Darker shade of primary color on hover */
    /*border-color: var(--color-primary-dark);*/
}

/* Custom CSS for positioning the references and militery logo */
.references {
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 10px;
}

.militery-logo-container {
    display: flex;
    justify-content: flex-end; /* Align items to the end of the container (right side) */
    margin-top: 20px;
}

.militery-logo {
    width: 150px; /* Adjust image width as needed */
    height: auto;
}

footer {
    background-color: var(--footer-background); /* Use footer background color */
    color: var(--footer-text); /* Use footer text color */
    text-align: center;
    padding: 20px 0;
}

/* CSS styles for smaller screens */
@media (max-width: 768px) {

    /* Adjustments for .home section */
    .home h1 {
        font-size: 2rem; /* Reduce font size for smaller screens */
        margin-top: -50px; /* Adjust margin for better positioning */
    }

    .home p {
        font-size: 1.2rem; /* Reduce font size for smaller screens */
        margin-bottom: 100px; /* Adjust margin for better spacing */
    }

    /* Navbar adjustments */
    .navbar-brand img {
        max-width: 50px; /* Adjust logo size for smaller screens */
        text-wrap: wrap;
    }

    .navbar-brand-content p {
        margin-right: 30px; /* Reduce right margin for text content */
        text-wrap: auto;
    }

    /* Blog card adjustments */
    .blog-card {
        padding: 20px; /* Reduce padding for smaller cards */
    }

    .blog-card .card-title {
        font-size: 16px; /* Reduce title font size for smaller screens */
    }

    .blog-card .card-text {
        font-size: 14px; /* Reduce text font size for smaller screens */
    }

    .references {
        flex-direction: column;
        align-items: flex-start;
    }

    .references h3 {
        margin-bottom: 10px;
    }

    .image-container {
        margin-top: 0;
        width: auto;
        height: auto;
    }

    .director-text {
        position: static;
        margin-top: 10px;
        background-color: transparent;
        padding: 0;
    }
    .about {
    padding: 50px 0;
    background-color: var(--background-color);
    color: var(--text-color);
    }

.about h1 {
    color: var(--primary-color);
    text-align: center;
}

.about p {
    margin-bottom: 20px;
}

/* Style for the container of the image and director text */
.image-container {
    text-align: center; /* Center align the content */
    margin-bottom: 20px; /* Add space below the container */
}

/* Style for the image inside the container */
.image-container img {
    max-width: 100%; /* Make sure the image does not exceed its container width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10vh; /* Apply border radius for rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
    margin-bottom: 50px;
}

}
@media (max-width: 768px) {
    .navbar-brand-content {
        font-size: 14px;
        padding: 5px;
    }
}
